<script lang="ts">
import { defineComponent } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";

import PageSidebarComponent from "@/layouts/AuthenticationLayouts/PoliceTouristAndAgent/PageSidebarComponent.vue";
import PageHeaderComponent from "@/layouts/AuthenticationLayouts/PoliceTouristAndAgent/PageHeaderComponent.vue";
import PageContentComponent from "@/layouts/AuthenticationLayouts/PoliceTouristAndAgent/PageContentComponent.vue";
import PageFooterComponent from "@/layouts/AuthenticationLayouts/PoliceTouristAndAgent/PageFooterComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { NativeAudio } from "@capacitor-community/native-audio";
import { Capacitor } from "@capacitor/core";
import { useAudioStore } from "@/stores/Shared/useAudioStore";
import { useAuthStore } from "@/views/PoliceTouristAndAgent/Authentication/stores/useAuthStore";

export default defineComponent({
  name: "AdminLayoutView",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    PageSidebarComponent,
    PageHeaderComponent,
    PageContentComponent,
    PageFooterComponent,
  },
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  methods: {
    async fetch() {
      await Promise.all([this.masterDataStore.listTitle(), this.masterDataStore.listLocale(), this.masterDataStore.listRole(), this.masterDataStore.listNationality(), this.masterDataStore.listIncidentCategory(), this.masterDataStore.listProvince()]);
    },
    setDayJSLang() {
      switch (this.masterDataStore.lang()) {
        case "TH":
          this.$dayjs.locale("th");
          break;
        case "CN":
          this.$dayjs.locale("zh");
          break;
        case "EN":
          this.$dayjs.locale("en");
          break;
        case "JP":
          this.$dayjs.locale("ja");
          break;
        case "RU":
          this.$dayjs.locale("ru");
          break;
        case "KR":
          this.$dayjs.locale("ko");
          break;
      }
    },
  },
  async mounted() {
    if (this.$route.meta.requiresAuth) {
      await this.fetch();
    }

    this.setDayJSLang();

    if (!Capacitor.isNativePlatform()) {
      this.sharedStore.initNotificationHandler();

      Promise.all(
        [
          {
            assetId: "call",
            assetPath: new URL(`@/assets/sounds/phone-outgoing-call-72202.mp3`, import.meta.url).href,
          },
          {
            assetId: "hang_up",
            assetPath: new URL(`@/assets/sounds/mobile_phone_hanging_up-94525.mp3`, import.meta.url).href,
          },
          {
            assetId: "waiting",
            assetPath: new URL(`@/assets/sounds/ringtone-126505.mp3`, import.meta.url).href,
          },
        ].map(({ assetId, assetPath }) =>
          NativeAudio.preload({
            assetId,
            assetPath,
            isUrl: true,
            volume: 1,
          })
        )
      );
    } else {
      this.sharedStore.initNotificationHandler();

      if (this.isLoggedIn) {
        this.sharedStore.registerNotification();
      }
    }
  },
  computed: {
    isLoggedIn() {
      return !!this.authStore.user
    }
  },
  setup() {
    const authStore = useAuthStore();
    const sharedStore = useSharedStore();
    const masterDataStore = useMasterDataStore();
    const audioStore = useAudioStore();

    return {
      authStore,
      sharedStore,
      masterDataStore,
      audioStore,
    };
  },
});
</script>

<template>
  <div
    id="page-container"
    class="flex flex-col mx-auto w-full min-h-screen min-w-[320px] bg-gray-100 overflow-hidden"
    :class="{
      'lg:pl-64': sharedStore.desktopSidebarOpen,
      dark: sharedStore.mode == 'true' ? false : true,
    }"
  >
    <PageSidebarComponent />
    <PageHeaderComponent />
    <PageContentComponent />
    <PageFooterComponent />
  </div>
</template>
