<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { useIncidentSearchStore } from "./stores/useIncidentSearchStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import type { $string } from "@/configs/types/Shared/typeShare";
import dayjs from "dayjs";

import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import { PERMISSION_INCIDENT_CREATE } from "@/configs/permission";
import { io } from "socket.io-client";

export default defineComponent({
  name: "IncidentView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      headers: [
        {
          key: "incidentNumber",
          label: this.$t("form.case_number.label"),
          parser: (v: any) => this.$convertString(v),
        },
        {
          key: "createdAt",
          label: this.$t("form.notification_date.label"),
          parser: (v: string) => this.$dayjs(v).format("DD/MM/YYYY HH:mm"),
        },
        {
          key: "informerName",
          label: this.$t("form.informer_name.label"),
          parser: (v: any) => this.$convertString(v),
        },
        {
          key: "contactNumber",
          label: this.$t("form.informer_phone.label"),
          parser: (v: any) => this.$convertString(v),
        },
        {
          key: "incidentCategory",
          label: this.$t("form.story_type.label"),
          className: "w-[200px] text-wrap inline-flex",
          parser: (v: any) => {
            const masterDataStore = useMasterDataStore();
            return this.$convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(v, this.$enums.ENUM_FIELD_LOCALE.TH));
          },
        },
        {
          key: "province",
          label: this.$t("form.province_area.label"),
          parser: (v: typeGetUser) => {
            return this.$convertString(v?.name?.TH);
          },
        },
        {
          key: "responsible",
          label: this.$t("form.recipient.label"),
          parser: (v: typeGetUser) => this.$convertString(v?.content[0]?.shortName),
        },
        {
          key: "updatedAt",
          label: this.$t("form.updated_date.label"),
          parser: (v: string) => this.$dayjs(v).format("DD/MM/YYYY HH:mm"),
        },
        { key: "status", label: this.$t("form.status.label") },
        { key: "action", label: "" },
      ],
      date: ref([null, dayjs().endOf("d").toDate()]) as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentSearchStore.error.keyword || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.incidentSearchStore.isRequired.keyword.is,
                textError: this.$t(this.incidentSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.incidentSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.incidentSearchStore.isRequired.keyword.placeholder),
                value: this.incidentSearchStore.result.keyword,
                name: this.incidentSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentSearchStore.result.keyword = value;
              },
            },
          },
          select: {
            type: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentSearchStore.error.type || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.incidentSearchStore.isRequired.type.is,
                textError: this.$t(this.incidentSearchStore.isRequired.type.placeholder),
                title: this.$t("form.type.label"),
                name: this.incidentSearchStore.isRequired.type.name,
                placeholder: this.$t(this.incidentSearchStore.isRequired.type.placeholder),
                value: this.incidentSearchStore.result.type,
                items: [
                  {
                    label: this.$enums.ENUM_INCIDENT_TYPE.DEFAULT,
                    value: this.$enums.ENUM_INCIDENT_TYPE.DEFAULT,
                  },
                  {
                    label: this.$enums.ENUM_INCIDENT_TYPE.SOS,
                    value: this.$enums.ENUM_INCIDENT_TYPE.SOS,
                  },
                ],
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentSearchStore.result.type = value;
              },
            },
            category: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentSearchStore.error.category || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.incidentSearchStore.isRequired.category.is,
                textError: this.$t(this.incidentSearchStore.isRequired.category.placeholder),
                title: this.$t("form.category.label"),
                name: this.incidentSearchStore.isRequired.category.name,
                placeholder: this.$t(this.incidentSearchStore.isRequired.category.placeholder),
                value: this.incidentSearchStore.result.categoryCode,
                items: this.masterDataStore.itemIncidentCategories(),
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentSearchStore.result.categoryCode = value;
              },
            },
          },
          button: {
            btnExport: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isDisable: true,
                htmlIcon: "",
                title: this.$t("btn.btn_export"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
            },
            btnCreate: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                title: this.$t("btn.btn_new_case"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnSearch: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_search"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnReset: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_reset"),
                classEnumName: this.$enums.ENUM_COLOR.YELLOW,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.incidentSearchStore.result.type = null;
                this.incidentSearchStore.result.keyword = null;
                this.resetDate();
              },
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
          popup: {
            reportCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentSearchStore.isOpenDefault,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.default_detail"),
                functionProp: () => {
                  this.incidentSearchStore.isOpenDefault = false;
                },
                functionCancelProp: () => {
                  this.incidentSearchStore.isOpenDefault = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            editCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentSearchStore.isOpenComplete,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.completed_detail"),
                functionProp: () => {
                  this.incidentSearchStore.isOpenComplete = false;
                },
                functionCancelProp: () => {
                  this.incidentSearchStore.isOpenComplete = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    isVisibleButtonManage() {
      return this.$inArray(this.authStore.displayRole, PERMISSION_INCIDENT_CREATE);
    },
    fetch() {
      this.incidentSearchStore.onUpdateCurrentPage(Number(this.$route.query.page) || 1);
    },
    loading() {
      return this.incidentSearchStore.loading;
    },
    async reset() {
      await this.incidentSearchStore.$reset();
    },
    async setting() {
      await this.reset();
      this.masterDataStore.listIncidentCategory();

      this.incidentSearchStore.userRole = this.authStore.displayRole;
      switch (this.$route.name) {
        case "AllIncidentView":
          this.incidentSearchStore.listType = "all";
          break;
        case "MyIncidentView":
          this.incidentSearchStore.listType = "me";
          break;
        default:
          this.incidentSearchStore.listType = "default";
          break;
      }

      this.incidentSearchStore.result.status = ((this.$route.meta.queryStatus || []) as string[]).join(",");
      this.incidentSearchStore.result.sort = this.$route.name === "AllIncidentView" ? "-createdAt" : "createdAt";

      this.incidentSearchStore.result.startAt = dayjs().startOf("d").subtract(1, "y").toISOString();
      this.incidentSearchStore.result.endAt = dayjs().endOf("d").toISOString();

      await this.fetch();
      await this.resetDate();
    },
    resetDate() {
      this.incidentSearchStore.result.startAt = dayjs().startOf("d").subtract(1, "y").toISOString();
      this.incidentSearchStore.result.endAt = dayjs().endOf("d").toISOString();

      this.date = ref([this.incidentSearchStore.result.startAt, this.incidentSearchStore.result.endAt]);
    },
    handleDate(modelData: any) {
      this.date.value = modelData;
      if (!this.date?.value?.length) {
        this.incidentSearchStore.result.startAt = dayjs().startOf("d").subtract(1, "y").toISOString();
        this.incidentSearchStore.result.endAt = dayjs().endOf("d").toISOString();
      } else {
        const [startAt, endAt] = this.date.value;

        if (startAt) {
          this.incidentSearchStore.result.startAt = dayjs(startAt).startOf("d").toISOString();
        } else {
          this.incidentSearchStore.result.startAt = null;
        }

        if (endAt) {
          this.incidentSearchStore.result.endAt = dayjs(endAt).endOf("d").toISOString();
        } else {
          this.incidentSearchStore.result.endAt = null;
        }
      }
      this.date = ref([this.incidentSearchStore.result.startAt, this.incidentSearchStore.result.endAt]);
    },
    dateRangeFormat(dates: Date[]) {
      const [start, end] = dates;

      const startString = !start || !dayjs(start).isValid() ? "เริ่มต้น" : dayjs(start).startOf("d").format("DD/MM/YYYY");
      const endString = !end || !dayjs(end).isValid() ? "ปัจจุบัน" : dayjs(end).startOf("d").format("DD/MM/YYYY");

      return `${startString} - ${endString}`;
    },
  },
  async mounted() {
    await this.setting();

    this.socket.connect();
    this.socket.on("updated", () => {
      this.fetch();
    });
  },
  async unmounted() {
    await this.reset();
  },
  created() {
    watch(
      () => this.$route.query.page,
      () => {
        this.fetch();
      }
    );
  },
  beforeUnmount() {
    this.socket.off("updated");
    this.socket.disconnect();
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const incidentSearchStore = useIncidentSearchStore();
    const authStore = useAuthStore();

    const socket = io(import.meta.env.VITE_INCIDENT_SOCKET_URL, {
      path: "/incident",
      secure: true,
      transports: ["websocket", "polling"],
      forceNew: true,
      upgrade: true,
    });

    return {
      masterDataStore,
      incidentSearchStore,
      authStore,
      socket,
    };
  },
});
</script>

<template>
  <div class="ttp-form">
    <div class="flex items-center justify-end grid grid-cols-6 gap-2">
      <component :classTagName="isVisibleButtonManage() ? 'w-full col-end-0 col-span-3 md:col-end-5 md:col-span-2 lg:col-end-6 lg:col-span-1' : 'w-full col-end-0 col-span-6 md:col-end-7 md:col-span-2 lg:col-end-7 lg:col-span-1'" :is="defaultFormComponents.form.button.btnExport.component" v-bind="defaultFormComponents.form.button.btnExport.props" />
      <router-link
        v-if="isVisibleButtonManage()"
        :to="{
          name: 'IncidentManageView',
        }"
        class="ml-2 col-span-3 md:col-span-2 lg:col-span-1"
      >
        <component classTagName="w-full" :is="defaultFormComponents.form.button.btnCreate.component" v-bind="defaultFormComponents.form.button.btnCreate.props" />
      </router-link>
    </div>
    <form @submit="incidentSearchStore.onSubmit">
      <div class="w-full gap-2">
        <div class="ttp-between">
          <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 w-full">
            <VueDatePicker :model-value="date" @update:model-value="handleDate" range multi-calendars :format="dateRangeFormat" class="w-full text-left" />
            <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.select.type.component" v-bind="defaultFormComponents.form.select.type.props" @updateValue="defaultFormComponents.form.select.type.onUpdateValue" />
            <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.select.category.component" v-bind="defaultFormComponents.form.select.category.props" @updateValue="defaultFormComponents.form.select.category.onUpdateValue" />
            <component :classTagName="'w-full text-left'" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
          </div>
          <div class="flex flex-cols gap-2 ttp-between">
            <component :is="defaultFormComponents.form.button.btnReset.component" v-bind="defaultFormComponents.form.button.btnReset.props" @click="defaultFormComponents.form.button.btnReset.click" />
            <component :is="defaultFormComponents.form.button.btnSearch.component" v-bind="defaultFormComponents.form.button.btnSearch.props" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <TableComponent :headers="headers" :content="incidentSearchStore.tableResults?.items" :loading="loading()" counter :counter-label="$t('form.number.label')">
    <template v-slot:body-informerName="props">
      {{ props?.item.informerName }}
      <span v-if="props?.item?.locale === 'TH'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/th.svg" width="25" height="auto" /></span>
      <span v-else-if="props?.item?.locale === 'EN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/gb-eng.svg" width="25" height="auto" /></span>
      <span v-else-if="props?.item?.locale === 'CN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/cn.svg" width="25" height="auto" /></span>
      <span v-else-if="props?.item?.locale === 'JP'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/jp.svg" width="25" height="auto" /></span>
      <span v-else-if="props?.item?.locale === 'KR'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kr.svg" width="25" height="auto" /></span>
      <span v-else-if="props?.item?.locale === 'RU'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/ru.svg" width="25" height="auto" /></span>
    </template>
    <template v-slot:body-status="props">
      <component :is="defaultFormComponents.form.badge.status.component" v-bind="defaultFormComponents.form.badge.status.props" :title="$formattedStatus($classStatus(props?.item?.status))" :classEnumName="$classStatus(props?.item?.status)" />
    </template>
    <template v-slot:body-action="props">
      <Menu as="div" class="inline-block">
        <MenuButton class="p-2">
          <component :is="$outlineIcons.EllipsisVerticalIcon" class="inline-block ttp-icon-inside-box-03" />
        </MenuButton>
        <MenuItems class="absolute right-1 origin-top-right z-10 mt-2 w-4/4 shadow-md rounded-lg dark:shadow-gray-900 focus:outline-none">
          <div class="bg-white ring-1 ring-black ring-opacity-5 rounded-lg divide-y divide-gray-100 dark:bg-gray-800 dark:divide-gray-700 dark:ring-gray-700">
            <div v-if="!$inArray(props?.item?.status, [$enums.ENUM_INCIDENT_STATUS.FORWARDED, $enums.ENUM_INCIDENT_STATUS.CLOSED, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.REJECTED])" class="space-y-1">
              <MenuItem v-slot="{ active }">
                <router-link
                  v-if="!$inArray(props?.item?.status, [$enums.ENUM_INCIDENT_STATUS.COMPLETED])"
                  :to="{
                    name: 'IncidentManageView',
                    params: { id: props?.item?.id },
                  }"
                  class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                  :class="{
                    'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                    'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                  }"
                >
                  <component :is="$outlineIcons.PencilSquareIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                  <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_manage") }}</span>
                </router-link>
                <a
                  v-else
                  @click="
                    () => {
                      incidentSearchStore.isOpenComplete = !incidentSearchStore.isOpenComplete;
                    }
                  "
                  href="#"
                  class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                  :class="{
                    'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                    'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                  }"
                >
                  <component :is="$outlineIcons.EyeIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                  <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_manage") }}</span>
                </a>
              </MenuItem>
            </div>
            <div class="space-y-1">
              <MenuItem v-slot="{ active }">
                <router-link
                  :to="{
                    name: 'IncidentNoticeView',
                    params: { id: props?.item?.id },
                  }"
                  class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                  :class="{
                    'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                    'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                  }"
                >
                  <component :is="$outlineIcons.EyeIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                  <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_view_notification") }}</span>
                </router-link>
              </MenuItem>
            </div>
            <div class="space-y-1">
              <MenuItem v-slot="{ active }">
                <router-link
                  :to="{
                    name: 'ChatManageView',
                    params: { id: props?.item?.id },
                  }"
                  class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                  :class="{
                    'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                    'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                  }"
                >
                  <component :is="$outlineIcons.ChatBubbleLeftEllipsisIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                  <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_chat") }}</span>
                </router-link>
              </MenuItem>
            </div>
            <div class="space-y-1">
              <MenuItem v-slot="{ active }">
                <router-link
                  :to="{
                    name: 'IncidentReportView',
                    params: { id: props?.item?.id },
                  }"
                  @click="
                    () => {
                      incidentSearchStore.isOpenDefault = !incidentSearchStore.isOpenDefault;
                    }
                  "
                  href="#"
                  class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                  :class="{
                    'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                    'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                  }"
                >
                  <component :is="$outlineIcons.ClipboardDocumentListIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                  <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_view_report") }}</span>
                </router-link>
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </Menu>
    </template>
  </TableComponent>
  <PaginationComponent
    :currentPage="Number($route.query.page) || 1"
    :totalPage="incidentSearchStore.totalPage"
    :gotoPage="incidentSearchStore.gotoPage"
    :nextPage="incidentSearchStore.nextPage"
    :prevPage="incidentSearchStore.prevPage"
    :onUpdateCurrentPage="
      () => {
        fetch();
      }
    "
  />

  <component :is="defaultFormComponents.form.popup.reportCaution.component" v-bind="defaultFormComponents.form.popup.reportCaution.props" />
  <component :is="defaultFormComponents.form.popup.editCaution.component" v-bind="defaultFormComponents.form.popup.editCaution.props" />
</template>
