<script lang="ts">
import { defineComponent } from "vue";

import { useOverviewSearchStore } from "./stores/useOverviewSearchStore";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";

import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { PERMISSION_INCIDENT_CREATE } from "@/configs/permission";
import { io } from "socket.io-client";

export default defineComponent({
  name: "ProfileEditView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
    };
  },
  computed: {
    components() {
      return {
        btnCreate: {
          component: ButtonDefaultComponent,
          props: {
            isSpecial: false,
            isVisible: true,
            isIcon: false,
            isLoading: this.loading(),
            isDisable: this.loading(),
            htmlIcon: "",
            title: this.$t("btn.btn_new_case"),
            classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
            size: this.$enums.ENUM_SIZE.SMALL,
          } as typeof ButtonDefaultComponent.props,
        },
        form: {
          button: {
            btnManage: {
              component: ButtonDefaultComponent,
              props: {
                classTagName: "w-full",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                componentIcon: this.$solidIcons.PencilSquareIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isSubmit: true,
                title: this.$t("btn.btn_manage"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnNotice: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                componentIcon: this.$solidIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isSubmit: true,
                title: this.$t("btn.btn_incident"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                htmlIcon: "",
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
      };
    },
    hasCreatePermission() {
      return this.$inArray(this.authStore.displayRole, PERMISSION_INCIDENT_CREATE);
    },
  },
  methods: {
    loading() {
      return this.overviewSearchStore.loadingDefault || this.overviewSearchStore.loadingSOS || this.overviewSearchStore.loadingReport;
    },
    async fetch() {
      await Promise.all([this.overviewSearchStore.onUpdateDefaultCurrentPage(this.overviewSearchStore.currentDefaultPage), this.overviewSearchStore.onUpdateSOSCurrentPage(this.overviewSearchStore.currentSOSPage), this.overviewSearchStore.onUpdateReport()]);
    },
    async reset() {
      await this.overviewSearchStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
    },
  },
  async mounted() {
    await this.setting();

    this.socket.connect();
    this.socket.on("updated", () => {
      this.fetch();
    });
  },
  async unmounted() {
    await this.reset();
  },
  beforeUnmount() {
    this.socket.off("updated");
    this.socket.disconnect();
  },
  setup() {
    const overviewSearchStore = useOverviewSearchStore();
    const authStore = useAuthStore();
    const masterDataStore = useMasterDataStore();

    const socket = io(import.meta.env.VITE_INCIDENT_SOCKET_URL, {
      path: "/incident",
      secure: true,
      transports: ["websocket", "polling"],
      forceNew: true,
      upgrade: true,
    });

    return {
      overviewSearchStore,
      authStore,
      masterDataStore,
      socket,
    };
  },
});
</script>

<template>
  <div class="ttp-layout">
    <div class="ttp-page-header-1">
      <h2>
        {{ $route?.meta?.isSpecial ? (!$route.params.id ? $t("layout.create") + $t(($route?.meta as any).i18n) : $t("layout.update") + $t(($route?.meta as any).i18n)) : $t(($route?.meta as any).i18n) }}
      </h2>
      <router-link v-if="hasCreatePermission" :to="{ name: 'IncidentManageView' }" class="ml-2">
        <component classTagName="w-full" :is="components.btnCreate.component" v-bind="components.btnCreate.props" />
      </router-link>
    </div>
  </div>
  <div class="flex flex-col md:flex-row gap-2">
    <div class="ttp-layout md:w-1/2 w-full">
      <div class="ttp-page-header-2 flex flex-col gap-0">
        <div class="flex w-full justify-center items-center border-b-2 p-5">
          <div class="w-full text-gray-800 ttp-text-base font-semibold text-left">
            {{ $t("page.OverviewView.title_left") }}
          </div>
          <router-link
            :to="{
              name: 'IncidentView',
            }"
            class="w-full text-right text-indigo-900 ttp-text-sm font-semibold"
          >
            {{ $t("btn.btn_all") }}
          </router-link>
        </div>
        <div class="flex gap-2 w-full border-b-2 pt-2">
          <div class="w-full ttp-text-sm font-semibold" :class="overviewSearchStore.isDefaultTab ? 'text-indigo-900' : 'text-gray-500'">
            <a
              href="#"
              class="flex gap-2 justify-center items-center px-3 h-10"
              @click="
                async () => {
                  overviewSearchStore.isDefaultTab = true;
                  if (!overviewSearchStore.loadingDefault) await overviewSearchStore.onUpdateDefaultCurrentPage(overviewSearchStore.currentDefaultPage);
                }
              "
            >
              <component :is="$solidIcons.BellIcon" class="inline-block ttp-icon-inside-box-03" />
              <div>{{ $t("page.OverviewView.tab_left") }}</div>
            </a>
            <div v-if="overviewSearchStore.isDefaultTab" class="self-stretch h-[0px] border-2 border-indigo-900"></div>
          </div>

          <div class="w-full text-center ttp-text-sm font-semibold" :class="!overviewSearchStore.isDefaultTab ? 'text-indigo-900' : 'text-gray-500'">
            <a
              href="#"
              class="flex gap-2 justify-center items-center px-3 h-10"
              @click="
                async () => {
                  overviewSearchStore.isDefaultTab = false;
                  if (!overviewSearchStore.loadingDefault) await overviewSearchStore.onUpdateDefaultAcceptCurrentPage(overviewSearchStore.currentDefaultAcceptPage);
                }
              "
              ><component :is="$solidIcons.ClipboardDocumentCheckIcon" class="inline-block ttp-icon-inside-box-03" />
              <div>{{ $t("page.OverviewView.tab_right") }}</div>
            </a>
            <div v-if="!overviewSearchStore.isDefaultTab" class="self-stretch h-[0px] border-2 border-indigo-900"></div>
          </div>
        </div>
        <div v-if="overviewSearchStore.isDefaultTab" class="h-[320px] overflow-auto w-full border-b-2">
          <div v-if="overviewSearchStore.loadingDefault" class="flex flex-col h-full justify-center items-center">
            <div class="flex gap-2">
              <LoadingFormComponent class="m-auto" />
              <div>{{ $t("layout.loading") }}</div>
            </div>
          </div>
          <div v-else-if="!overviewSearchStore?.tableDefaultResults?.items?.length" class="flex flex-col h-full justify-center items-center">
            <div class="text-center">
              <component :is="$outlineIcons.BookOpenIcon" class="inline-block m-auto ttp-icon-box-01" />
              <div class="ttp-text-xs">{{ $t("page.OverviewView.not_found") }}</div>
            </div>
          </div>
          <div v-else v-for="(result, index) in overviewSearchStore?.tableDefaultResults?.items" :key="index" class="flex flex-col md:flex-row gap-2 p-2 w-full justify-center items-center border-b-2">
            <div class="w-[100px]">
              <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
              </div>
            </div>
            <div class="w-full">
              <template v-if="result?.incidentCategory?.length">
                <div class="text-indigo-800 ttp-text-base font-semibold text-left">
                  <template v-if="result?.incidentCategory?.length">
                    {{ $convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(result?.incidentCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                  </template>
                  <template v-else>
                    {{ $convertString(masterDataStore.getIncidentFriendlyCategoryByCodeAndLocale(result?.incidentFriendlyCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                  </template>
                </div>
              </template>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-medium text-left">
                <component :is="$solidIcons.IdentificationIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.report_no") }} {{ result?.incidentNumber }}</span>
              </div>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-normal text-left">
                <component :is="$solidIcons.UserCircleIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" />
                <span class="ttp-text-xs font-medium ml-1">
                  {{ result?.informerType === $enums.ENUM_INFORMER_TYPE.ANONYMOUS ? $t("layout.anonymous") : result?.informerName }}
                </span>
                <span v-if="result?.locale === 'TH'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/th.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'EN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/gb-eng.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'CN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/cn.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'JP'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/jp.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'KR'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kr.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'RU'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/ru.svg" width="25" height="auto" /></span>
              </div>
              <div class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(result?.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
              </div>
              <div class="text-left">
                <component :is="components.form.badge.status.component" v-bind="components.form.badge.status.props" :title="$formattedStatus($classStatus(result?.status))" :classEnumName="$classStatus(result?.status)" />
              </div>
            </div>
            <div class="w-[265px]">
              <router-link v-if="hasCreatePermission" :to="{ name: 'IncidentManageView', params: { id: result?.id } }">
                <component :classTagName="'w-full'" :classIcon="'ttp-icon-inside-box-03 inline-block'" :is="components.form.button.btnManage.component" v-bind="components.form.button.btnManage.props" />
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'IncidentNoticeView',
                  params: { id: result?.id },
                }"
              >
                <component :classTagName="'w-full'" :is="components.form.button.btnNotice.component" v-bind="components.form.button.btnNotice.props" />
              </router-link>
            </div>
          </div>
        </div>
        <div v-else class="h-[320px] overflow-auto w-full border-b-2">
          <div v-if="overviewSearchStore.loadingDefault" class="flex flex-col h-full justify-center items-center">
            <div class="flex gap-2">
              <LoadingFormComponent class="m-auto" />
              <div>{{ $t("layout.loading") }}</div>
            </div>
          </div>
          <div v-else-if="!overviewSearchStore?.tableDefaultAcceptResults?.items?.length" class="flex flex-col h-full justify-center items-center">
            <div class="text-center">
              <component :is="$outlineIcons.BookOpenIcon" class="inline-block m-auto ttp-icon-box-01" />
              <div class="ttp-text-xs">{{ $t("page.OverviewView.not_found") }}</div>
            </div>
          </div>
          <div v-else v-for="(result, index) in overviewSearchStore?.tableDefaultAcceptResults?.items" :key="index" class="flex flex-col md:flex-row gap-2 p-2 w-full justify-center items-center border-b-2">
            <div class="w-[100px]">
              <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
              </div>
            </div>
            <div class="w-full">
              <div class="text-indigo-800 ttp-text-base font-semibold text-left">
                <template v-if="result?.incidentCategory?.length">
                  {{ $convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(result?.incidentCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                </template>
                <template v-else>
                  {{ $convertString(masterDataStore.getIncidentFriendlyCategoryByCodeAndLocale(result?.incidentFriendlyCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                </template>
              </div>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-medium text-left">
                <component :is="$solidIcons.IdentificationIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.report_no") }} {{ result?.incidentNumber }}</span>
              </div>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-normal text-left">
                <component :is="$solidIcons.UserCircleIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" />
                <span class="ttp-text-xs font-medium ml-1">
                  {{ result?.informerType === $enums.ENUM_INFORMER_TYPE.ANONYMOUS ? $t("layout.anonymous") : result?.informerName }}
                </span>
                <span v-if="result?.locale === 'TH'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/th.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'EN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/gb-eng.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'CN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/cn.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'JP'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/jp.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'KR'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kr.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'RU'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/ru.svg" width="25" height="auto" /></span>
              </div>
              <div class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(result?.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
              </div>
              <div class="text-left">
                <component :is="components.form.badge.status.component" v-bind="components.form.badge.status.props" :title="$formattedStatus($classStatus(result?.status))" :classEnumName="$classStatus(result?.status)" />
              </div>
            </div>
            <div class="w-[265px]">
              <router-link v-if="hasCreatePermission" :to="{ name: 'IncidentManageView', params: { id: result?.id } }">
                <component :classTagName="'w-full'" :classIcon="'ttp-icon-inside-box-03 inline-block'" :is="components.form.button.btnManage.component" v-bind="components.form.button.btnManage.props" />
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'IncidentNoticeView',
                  params: { id: result?.id },
                }"
              >
                <component :classTagName="'w-full'" :is="components.form.button.btnNotice.component" v-bind="components.form.button.btnNotice.props" />
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="overviewSearchStore.isDefaultTab" class="flex w-full gap-2 justify-center items-center px-4 py-2">
          <div class="w-full text-gray-500 ttp-text-sm font-semibold">
            {{ $t("layout.all") }}
            {{ overviewSearchStore?.tableDefaultResults?.total }}
            {{ $t("layout.case") }}
          </div>
          <div class="flex gap-2 w-full justify-end items-center">
            <div v-if="overviewSearchStore?.tableDefaultResults?.totalPages" class="ttp-text-xs">Page {{ overviewSearchStore?.tableDefaultResults?.page }} of {{ overviewSearchStore?.tableDefaultResults?.totalPages }}</div>
            <div class="inline-flex">
              <button
                type="button"
                class="inline-flex justify-center items-center space-x-2 border font-semibold rounded-l-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                @click="
                  (e) => {
                    overviewSearchStore.prevDefaultPage(e);
                  }
                "
              >
                <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" /></button
              ><button
                type="button"
                class="-ml-px inline-flex justify-center items-center space-x-2 border font-semibold rounded-r-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                @click="
                  (e) => {
                    overviewSearchStore.nextDefaultPage(e);
                  }
                "
              >
                <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" />
              </button>
            </div>
          </div>
        </div>
        <div v-else class="flex w-full gap-2 justify-center items-center px-4 py-2">
          <div class="w-full text-gray-500 ttp-text-sm font-semibold">
            {{ $t("layout.all") }}
            {{ overviewSearchStore?.tableDefaultAcceptResults?.total }}
            {{ $t("layout.case") }}
          </div>
          <div class="flex gap-2 w-full justify-end items-center">
            <div v-if="overviewSearchStore?.tableDefaultAcceptResults?.totalPages" class="ttp-text-xs">Page {{ overviewSearchStore?.tableDefaultAcceptResults?.page }} of {{ overviewSearchStore?.tableDefaultAcceptResults?.totalPages }}</div>
            <div class="inline-flex">
              <button
                type="button"
                class="inline-flex justify-center items-center space-x-2 border font-semibold rounded-l-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                @click="
                  (e) => {
                    overviewSearchStore.prevDefaultAcceptPage(e);
                  }
                "
              >
                <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" /></button
              ><button
                type="button"
                class="-ml-px inline-flex justify-center items-center space-x-2 border font-semibold rounded-r-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
                @click="
                  (e) => {
                    overviewSearchStore.nextDefaultAcceptPage(e);
                  }
                "
              >
                <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ttp-layout md:w-1/2 w-full">
      <div class="ttp-page-header-2 flex flex-col gap-0">
        <div class="flex w-full justify-center items-center border-b-2 p-5">
          <div class="w-full text-gray-800 ttp-text-base font-semibold text-left">
            {{ $t("page.OverviewView.title_right") }}
          </div>
          <router-link
            :to="{
              name: 'IncidentView',
            }"
            class="w-full text-right text-indigo-900 ttp-text-sm font-semibold"
          >
            {{ $t("btn.btn_all") }}
          </router-link>
        </div>
        <div class="flex gap-2 w-full border-b-2 pt-2">
          <div class="w-full ttp-text-sm font-semibold" :class="overviewSearchStore.isSOSTab ? 'text-indigo-900' : 'text-gray-500'">
            <a
              href="#"
              class="flex gap-2 justify-center items-center px-3 h-10"
              @click="
                async () => {
                  overviewSearchStore.isSOSTab = true;
                  if (!overviewSearchStore.loadingSOS) await overviewSearchStore.onUpdateSOSCurrentPage(overviewSearchStore.currentSOSPage);
                }
              "
            >
              <component :is="$solidIcons.BellIcon" class="inline-block ttp-icon-inside-box-03" />
              <div>{{ $t("page.OverviewView.tab_left") }}</div>
            </a>
            <div v-if="overviewSearchStore.isSOSTab" class="self-stretch h-[0px] border-2 border-indigo-900"></div>
          </div>

          <div class="w-full text-center ttp-text-sm font-semibold" :class="!overviewSearchStore.isSOSTab ? 'text-indigo-900' : 'text-gray-500'">
            <a
              href="#"
              class="flex gap-2 justify-center items-center px-3 h-10"
              @click="
                async () => {
                  overviewSearchStore.isSOSTab = false;
                  if (!overviewSearchStore.loadingSOS) await overviewSearchStore.onUpdateSOSAcceptCurrentPage(overviewSearchStore.currentSOSAcceptPage);
                }
              "
              ><component :is="$solidIcons.ClipboardDocumentCheckIcon" class="inline-block ttp-icon-inside-box-03" />
              <div>{{ $t("page.OverviewView.tab_right") }}</div>
            </a>
            <div v-if="!overviewSearchStore.isSOSTab" class="self-stretch h-[0px] border-2 border-indigo-900"></div>
          </div>
        </div>
        <div v-if="overviewSearchStore.isSOSTab" class="h-[320px] overflow-auto w-full border-b-2">
          <div v-if="overviewSearchStore.loadingSOS" class="flex flex-col h-full justify-center items-center">
            <div class="flex gap-2">
              <LoadingFormComponent class="m-auto" />
              <div>{{ $t("layout.loading") }}</div>
            </div>
          </div>
          <div v-else-if="!overviewSearchStore?.tableSOSResults?.items?.length" class="flex flex-col h-full justify-center items-center">
            <div class="text-center">
              <component :is="$outlineIcons.BookOpenIcon" class="inline-block m-auto ttp-icon-box-01" />
              <div class="ttp-text-xs">{{ $t("page.OverviewView.not_found") }}</div>
            </div>
          </div>
          <div v-else v-for="(result, index) in overviewSearchStore?.tableSOSResults?.items" :key="index" class="flex flex-col md:flex-row gap-2 p-2 w-full justify-center items-center border-b-2">
            <div class="w-[100px]">
              <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
              </div>
            </div>
            <div class="w-full">
              <div class="text-indigo-800 ttp-text-base font-semibold text-left">
                <template v-if="result?.incidentCategory?.length">
                  {{ $convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(result?.incidentCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                </template>
                <template v-else>
                  {{ $convertString(masterDataStore.getIncidentFriendlyCategoryByCodeAndLocale(result?.incidentFriendlyCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                </template>
              </div>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-medium text-left">
                <component :is="$solidIcons.IdentificationIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.report_no") }} {{ result?.incidentNumber }}</span>
              </div>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-normal text-left">
                <component :is="$solidIcons.UserCircleIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" />
                <span class="ttp-text-xs font-medium ml-1">
                  {{ result?.informerType === $enums.ENUM_INFORMER_TYPE.ANONYMOUS ? $t("layout.anonymous") : result?.informerName }}
                </span>
                <span v-if="result?.locale === 'TH'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/th.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'EN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/gb-eng.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'CN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/cn.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'JP'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/jp.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'KR'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kr.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'RU'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/ru.svg" width="25" height="auto" /></span>
              </div>
              <div class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(result?.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
              </div>
              <div class="text-left">
                <component :is="components.form.badge.status.component" v-bind="components.form.badge.status.props" :title="$formattedStatus($classStatus(result?.status))" :classEnumName="$classStatus(result?.status)" />
              </div>
            </div>
            <div class="w-[265px]">
              <router-link v-if="hasCreatePermission" :to="{ name: 'IncidentManageView', params: { id: result?.id } }">
                <component :classTagName="'w-full'" :classIcon="'ttp-icon-inside-box-03 inline-block'" :is="components.form.button.btnManage.component" v-bind="components.form.button.btnManage.props" />
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'IncidentNoticeView',
                  params: { id: result?.id },
                }"
              >
                <component :classTagName="'w-full'" :is="components.form.button.btnNotice.component" v-bind="components.form.button.btnNotice.props" />
              </router-link>
            </div>
          </div>
        </div>
        <div v-else class="h-[320px] overflow-auto w-full border-b-2">
          <div v-if="overviewSearchStore.loadingSOS" class="flex flex-col h-full justify-center items-center">
            <div class="flex gap-2">
              <LoadingFormComponent class="m-auto" />
              <div>{{ $t("layout.loading") }}</div>
            </div>
          </div>
          <div v-else-if="!overviewSearchStore?.tableSOSAcceptResults?.items?.length" class="flex flex-col h-full justify-center items-center">
            <div class="text-center">
              <component :is="$outlineIcons.BookOpenIcon" class="inline-block m-auto ttp-icon-box-01" />
              <div class="ttp-text-xs">{{ $t("page.OverviewView.not_found") }}</div>
            </div>
          </div>
          <div v-else v-for="(result, index) in overviewSearchStore?.tableSOSAcceptResults?.items" :key="index" class="flex flex-col md:flex-row gap-2 p-2 w-full justify-center items-center border-b-2">
            <div class="w-[100px]">
              <div class="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
                <component :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-02" />
              </div>
            </div>
            <div class="w-full">
              <div class="text-indigo-800 ttp-text-base font-semibold text-left">
                <template v-if="result?.incidentCategory?.length">
                  {{ $convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(result?.incidentCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                </template>
                <template v-else>
                  {{ $convertString(masterDataStore.getIncidentFriendlyCategoryByCodeAndLocale(result?.incidentFriendlyCategory, $enums.ENUM_FIELD_LOCALE.TH)) }}
                </template>
              </div>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-medium text-left">
                <component :is="$solidIcons.IdentificationIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $t("page.HistoryDetailPoliceTouristAndAgentView.report_no") }} {{ result?.incidentNumber }}</span>
              </div>
              <div class="text-gray-600 text-opacity-90 ttp-text-xs font-normal text-left">
                <component :is="$solidIcons.UserCircleIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" />
                <span class="ttp-text-xs font-medium ml-1">
                  {{ result?.informerType === $enums.ENUM_INFORMER_TYPE.ANONYMOUS ? $t("layout.anonymous") : result?.informerName }}
                </span>
                <span v-if="result?.locale === 'TH'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/th.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'EN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/gb-eng.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'CN'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/cn.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'JP'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/jp.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'KR'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/kr.svg" width="25" height="auto" /></span>
                <span v-else-if="result?.locale === 'RU'"><img src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/ru.svg" width="25" height="auto" /></span>
              </div>
              <div class="text-gray-500 ttp-text-xs font-normal leading-snug tracking-tight text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03 text-ttp-primary" /> <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(result?.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
              </div>
              <div class="text-left">
                <component :is="components.form.badge.status.component" v-bind="components.form.badge.status.props" :title="$formattedStatus($classStatus(result?.status))" :classEnumName="$classStatus(result?.status)" />
              </div>
            </div>
            <div class="w-[265px]">
              <router-link v-if="hasCreatePermission" :to="{ name: 'IncidentManageView', params: { id: result?.id } }">
                <component :classTagName="'w-full'" :classIcon="'ttp-icon-inside-box-03 inline-block'" :is="components.form.button.btnManage.component" v-bind="components.form.button.btnManage.props" />
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'IncidentNoticeView',
                  params: { id: result?.id },
                }"
              >
                <component :classTagName="'w-full'" :is="components.form.button.btnNotice.component" v-bind="components.form.button.btnNotice.props" />
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="overviewSearchStore.isSOSTab" class="flex w-full gap-2 justify-center items-center px-4 py-2">
          <div class="w-full text-gray-500 ttp-text-sm font-semibold">
            {{ $t("layout.all") }}
            {{ overviewSearchStore?.tableSOSResults?.total }}
            {{ $t("layout.case") }}
          </div>
          <div class="flex gap-2 w-full justify-end items-center">
            <div v-if="overviewSearchStore?.tableSOSResults?.totalPages" class="ttp-text-xs">Page {{ overviewSearchStore?.tableSOSResults?.page }} of {{ overviewSearchStore?.tableSOSResults?.totalPages }}</div>
            <div class="inline-flex">
              <button
                @click="
                  (e) => {
                    overviewSearchStore.prevSOSPage(e);
                  }
                "
                type="button"
                class="inline-flex justify-center items-center space-x-2 border font-semibold rounded-l-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
              >
                <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" /></button
              ><button
                @click="
                  (e) => {
                    overviewSearchStore.nextSOSPage(e);
                  }
                "
                type="button"
                class="-ml-px inline-flex justify-center items-center space-x-2 border font-semibold rounded-r-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
              >
                <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" />
              </button>
            </div>
          </div>
        </div>
        <div v-else class="flex w-full gap-2 justify-center items-center px-4 py-2">
          <div class="w-full text-gray-500 ttp-text-sm font-semibold">
            {{ $t("layout.all") }}
            {{ overviewSearchStore?.tableSOSAcceptResults?.total }}
            {{ $t("layout.case") }}
          </div>
          <div class="flex gap-2 w-full justify-end items-center">
            <div v-if="overviewSearchStore?.tableSOSAcceptResults?.totalPages" class="ttp-text-xs">Page {{ overviewSearchStore?.tableSOSAcceptResults?.page }} of {{ overviewSearchStore?.tableSOSAcceptResults?.totalPages }}</div>
            <div class="inline-flex">
              <button
                @click="
                  (e) => {
                    overviewSearchStore.prevSOSAcceptPage(e);
                  }
                "
                type="button"
                class="inline-flex justify-center items-center space-x-2 border font-semibold rounded-l-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
              >
                <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" /></button
              ><button
                @click="
                  (e) => {
                    overviewSearchStore.nextSOSAcceptPage(e);
                  }
                "
                type="button"
                class="-ml-px inline-flex justify-center items-center space-x-2 border font-semibold rounded-r-lg px-4 py-2 leading-6 border-gray-200 bg-white text-gray-800 hover:z-1 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:z-1 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:z-1 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700"
              >
                <component :is="$solidIcons.ChevronRightIcon" class="inline-block ttp-icon-inside-box-03 -mx-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ttp-layout">
    <div class="ttp-page-header-1">
      <div class="w-full text-center">
        <div class="text-indigo-900 ttp-text-xl font-bold">
          {{ $convertNumber(overviewSearchStore?.reportResult?.ACCEPTED) }}
        </div>
        <div class="ttp-text-xs">รับแจ้งเหตุแล้ว</div>
        <div class="ttp-text-xs">(Accepted)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-orange-400 ttp-text-xl font-bold">
          {{ $convertNumber(overviewSearchStore?.reportResult?.WAITING) }}
        </div>
        <div class="ttp-text-xs">รอดำเนินการ</div>
        <div class="ttp-text-xs">(Waiting)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-orange-400 ttp-text-xl font-bold">
          {{ $convertNumber(overviewSearchStore?.reportResult?.IN_PROGRESS) }}
        </div>
        <div class="ttp-text-xs">กำลังดำเนินการ</div>
        <div class="ttp-text-xs">(In Progress)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-green-600 ttp-text-xl font-bold">
          {{ $convertNumber(overviewSearchStore?.reportResult?.COMPLETED) }}
        </div>
        <div class="ttp-text-xs">แก้ไขเรียบร้อย</div>
        <div class="ttp-text-xs">(Completed)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-gray-800 ttp-text-xl font-bold">
          {{ $convertNumber(overviewSearchStore?.reportResult?.REPORTED) }}
        </div>
        <div class="ttp-text-xs">รับรายงาน</div>
        <div class="ttp-text-xs">(Reported)</div>
      </div>
      <div class="w-full md:w-2 h-1 md:h-16 bg-slate-200"></div>
      <div class="w-full text-center">
        <div class="text-gray-800 ttp-text-xl font-bold">
          {{ $convertNumber(overviewSearchStore?.reportResult?.CLOSED) }}
        </div>
        <div class="ttp-text-xs">ปิดงาน</div>
        <div class="ttp-text-xs">(Closed)</div>
      </div>
    </div>
  </div>
</template>
