<script lang="ts">
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
import { defineComponent, onMounted, ref, type Ref } from "vue";
import { useDashboardSearchStore } from "./stores/useDashboardSearchStore";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import type { $enum, $string } from "@/configs/types/Shared/typeShare";
import { ENUM_CHANNEL } from "@/configs/enums/enum";

export default defineComponent({
  name: "DashboardIncidentTypeView",
  components: {},
  data() {
    const today = dayjs();

    return {
      componentName: this.$options.name,
      date: ref([today.subtract(1, "month").tz("Asia/Bangkok").format("YYYY-MM-DD"), today.tz("Asia/Bangkok").format("YYYY-MM-DD")]) as Ref<string[]>,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          select: {
            categoryCode: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.dashboardSearchStore.error[this.dashboardSearchStore.isRequired.categoryCode.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.dashboardSearchStore.isRequired.categoryCode.is,
                textError: this.$t(this.dashboardSearchStore.isRequired.categoryCode.placeholder),
                title: this.$t(this.dashboardSearchStore.isRequired.categoryCode.label),
                placeholder: this.$t(this.dashboardSearchStore.isRequired.categoryCode.placeholder),
                value: this.dashboardSearchStore?.result?.categoryCode,
                items: this.masterDataStore.itemIncidentCategories(),
                name: this.dashboardSearchStore.isRequired.categoryCode.name,
                functionProp: async (value: any) => {
                  this.masterDataStore.loadingTopicCode = true;
                  this.masterDataStore.incidentTopics = [];
                  this.dashboardSearchStore.result.topicCode = "";
                  this.masterDataStore.categoryCode = value;
                  await this.masterDataStore.listIncidentTopic(value);
                  this.masterDataStore.loadingTopicCode = false;
                },
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.dashboardSearchStore.result.categoryCode = value;
              },
            },
            topicCode: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.dashboardSearchStore.error[this.dashboardSearchStore.isRequired.topicCode.name] || "").length > 0,
                isIcon: false,
                isDisable: !this.dashboardSearchStore?.result?.categoryCode || this.loading(),
                isRequired: this.dashboardSearchStore.isRequired.topicCode.is,
                textError: this.$t(this.dashboardSearchStore.isRequired.topicCode.placeholder),
                title: this.$t(this.dashboardSearchStore.isRequired.topicCode.label),
                placeholder: this.$t(this.dashboardSearchStore.isRequired.topicCode.placeholder),
                value: this.dashboardSearchStore?.result?.topicCode,
                items: this.masterDataStore.itemIncidentTopics(),
                name: this.dashboardSearchStore.isRequired.topicCode.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.dashboardSearchStore.result.topicCode = value;
              },
            },
            channel: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: false,
                isError: (this.dashboardSearchStore.error[this.dashboardSearchStore.isRequired.channel.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.dashboardSearchStore.isRequired.channel.is,
                textError: this.$t(this.dashboardSearchStore.isRequired.channel.placeholder),
                title: this.$t(this.dashboardSearchStore.isRequired.channel.label),
                placeholder: this.$t(this.dashboardSearchStore.isRequired.channel.placeholder),
                value: this.dashboardSearchStore?.result?.channel,
                items: this.masterDataStore.itemChannels(),
                name: this.dashboardSearchStore.isRequired.channel.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: ENUM_CHANNEL | $enum) => {
                this.dashboardSearchStore.result.channel = value;
              },
            },
          },
          button: {
            btnSearch: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_search"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async (e: any) => {
                const validate = await this.dashboardSearchStore.onSubmit(e);
                if (validate) {
                  await this.fetch();
                  await this.generateCharts();
                }
              },
            },
            btnReset: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_reset"),
                classEnumName: this.$enums.ENUM_COLOR.YELLOW,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.dashboardSearchStore.result.categoryCode = null;
                this.dashboardSearchStore.result.topicCode = null;
                this.resetDate();
              },
            },
          },
        },
      };
    },
  },
  methods: {
    loading() {
      return this.dashboardSearchStore.loading;
    },
    async setting() {
      await this.$configLightbox();
    },
    resetDate() {
      const today = dayjs();
      this.date = [today.subtract(1, "month").tz("Asia/Bangkok").format("YYYY-MM-DD"), today.tz("Asia/Bangkok").format("YYYY-MM-DD")];
    },
    handleDate([start, end]: string[]) {
      const endDay = end ? dayjs(end) : dayjs();
      const startDay = start ? dayjs(end) : endDay.subtract(1, "month");
      this.date = [startDay.tz("Asia/Bangkok").format("YYYY-MM-DD"), endDay.tz("Asia/Bangkok").format("YYYY-MM-DD")];
    },
    dateRangeFormat(dates: Date[]) {
      const [start, end] = dates;

      const startString = !start || !dayjs(start).isValid() ? "เริ่มต้น" : dayjs(start).format("YYYY-MM-DD");
      const endString = !end || !dayjs(end).isValid() ? "ปัจจุบัน" : dayjs(end).format("YYYY-MM-DD");

      return `${startString} - ${endString}`;
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {},
  setup() {
    const dashboardSearchStore = useDashboardSearchStore();
    const masterDataStore = useMasterDataStore();

    const incidentProvince_Month_Application_Results = ref<HTMLCanvasElement | null>(null);
    const incidentStation_Month_Application_Results = ref<HTMLCanvasElement | null>(null);
    const incidentSubDivision_Month_Application_Results = ref<HTMLCanvasElement | null>(null);
    const incidentDivision_Month_Application_Results = ref<HTMLCanvasElement | null>(null);
    // const incidentA_Month_Application_Results = ref<HTMLCanvasElement | null>(null);
    const lineIncidentDay_Application_Results = ref<HTMLCanvasElement | null>(null);

    Chart.register(...registerables);

    onMounted(async () => {
      if (dashboardSearchStore.result) {
        dashboardSearchStore.$reset();
        dashboardSearchStore.result.startDate = dayjs("1970-01-01").format("YYYY-MM-DD");
        dashboardSearchStore.result.endDate = dayjs().format("YYYY-MM-DD");
        await fetch();
        await generateCharts();
      }
    });

    async function fetch() {
      dashboardSearchStore.result.channel = ENUM_CHANNEL.APPLICATION;
      const incidentProvince_Month_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "province_id", "month");
      const incidentStation_Month_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "station", "month");
      const incidentSubDivision_Month_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "sub_division", "month");
      const incidentDivision_Month_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "division", "month");
      // const incidentA_Month_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "nationality", "month");
      const lineIncidentDay_Application_Results = await dashboardSearchStore.fetchReport.IncidentReport(dashboardSearchStore.result, "category_code", "month");

      dashboardSearchStore.loading = false;

      dashboardSearchStore.incidentProvince_Month_Application_Results = incidentProvince_Month_Application_Results ? await masterDataStore.transformIncidentReportProvinces(incidentProvince_Month_Application_Results) : null;
      dashboardSearchStore.incidentStation_Month_Application_Results = incidentStation_Month_Application_Results ? await masterDataStore.transformIncidentReportOrganizations(incidentStation_Month_Application_Results) : null;
      dashboardSearchStore.incidentSubDivision_Month_Application_Results = incidentSubDivision_Month_Application_Results ? await masterDataStore.transformIncidentReportOrganizations(incidentSubDivision_Month_Application_Results) : null;
      dashboardSearchStore.incidentDivision_Month_Application_Results = incidentDivision_Month_Application_Results ? await masterDataStore.transformIncidentReportOrganizations(incidentDivision_Month_Application_Results) : null;
      // dashboardSearchStore.incidentA_Month_Application_Results = incidentA_Month_Application_Results ? await masterDataStore.transformIncidentReportOrganizations(incidentA_Month_Application_Results) : null;
      dashboardSearchStore.lineIncidentDay_Application_Results = lineIncidentDay_Application_Results ? await masterDataStore.transformIncidentCategoryReport(lineIncidentDay_Application_Results) : null;
    }

    let Chart_incidentProvince_Month_Application_Results: Chart<"pie", number[], string> | undefined;
    let Chart_incidentStation_Month_Application_Results: Chart<"pie", number[], string> | undefined;
    let Chart_incidentSubDivision_Month_Application_Results: Chart<"pie", number[], string> | undefined;
    let Chart_incidentDivision_Month_Application_Results: Chart<"pie", number[], string> | undefined;
    let Chart_lineIncidentDay_Application_Results: Chart<"line", number[], string> | undefined;

    async function generateCharts() {
      if (Chart_incidentProvince_Month_Application_Results) {
        Chart_incidentProvince_Month_Application_Results.destroy();
        Chart_incidentProvince_Month_Application_Results = undefined;
      }
      if (Chart_incidentStation_Month_Application_Results) {
        Chart_incidentStation_Month_Application_Results.destroy();
        Chart_incidentStation_Month_Application_Results = undefined;
      }
      if (Chart_incidentSubDivision_Month_Application_Results) {
        Chart_incidentSubDivision_Month_Application_Results.destroy();
        Chart_incidentSubDivision_Month_Application_Results = undefined;
      }
      if (Chart_incidentDivision_Month_Application_Results) {
        Chart_incidentDivision_Month_Application_Results.destroy();
        Chart_incidentDivision_Month_Application_Results = undefined;
      }
      if (Chart_lineIncidentDay_Application_Results) {
        Chart_lineIncidentDay_Application_Results.destroy();
        Chart_lineIncidentDay_Application_Results = undefined;
      }

      if (incidentProvince_Month_Application_Results.value) {
        const incidentProvince_Month_Application_Results_Context = incidentProvince_Month_Application_Results.value.getContext("2d");
        if (incidentProvince_Month_Application_Results_Context) {
          Chart_incidentProvince_Month_Application_Results = new Chart(incidentProvince_Month_Application_Results_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.incidentProvince_Month_Application_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: dashboardSearchStore.incidentProvince_Month_Application_Results.value,
                },
              ],
            },
          });
        }
      }
      if (incidentStation_Month_Application_Results.value) {
        const incidentStation_Month_Application_Results_Context = incidentStation_Month_Application_Results.value.getContext("2d");
        if (incidentStation_Month_Application_Results_Context) {
          Chart_incidentStation_Month_Application_Results = new Chart(incidentStation_Month_Application_Results_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.incidentStation_Month_Application_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: dashboardSearchStore.incidentStation_Month_Application_Results.value,
                },
              ],
            },
          });
        }
      }
      if (incidentSubDivision_Month_Application_Results.value) {
        const incidentSubDivision_Month_Application_Results_Context = incidentSubDivision_Month_Application_Results.value.getContext("2d");
        if (incidentSubDivision_Month_Application_Results_Context) {
          Chart_incidentSubDivision_Month_Application_Results = new Chart(incidentSubDivision_Month_Application_Results_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.incidentSubDivision_Month_Application_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: dashboardSearchStore.incidentSubDivision_Month_Application_Results.value,
                },
              ],
            },
          });
        }
      }
      if (incidentDivision_Month_Application_Results.value) {
        const incidentDivision_Month_Application_Results_Context = incidentDivision_Month_Application_Results.value.getContext("2d");
        if (incidentDivision_Month_Application_Results_Context) {
          Chart_incidentDivision_Month_Application_Results = new Chart(incidentDivision_Month_Application_Results_Context, {
            type: "pie",
            data: {
              labels: dashboardSearchStore.incidentDivision_Month_Application_Results.label,
              datasets: [
                {
                  label: "จำนวน",
                  data: dashboardSearchStore.incidentDivision_Month_Application_Results.value,
                },
              ],
            },
          });
        }
      }
      if (lineIncidentDay_Application_Results.value) {
        const lineIncidentDay_Application_Results_Context = lineIncidentDay_Application_Results.value.getContext("2d");
        if (lineIncidentDay_Application_Results_Context) {
          Chart_lineIncidentDay_Application_Results = new Chart(lineIncidentDay_Application_Results_Context, {
            type: "line",
            data: dashboardSearchStore.lineIncidentDay_Application_Results,
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              responsive: true,
            },
          });
        }
      }
    }

    return {
      dashboardSearchStore,
      masterDataStore,
      generateCharts,
      fetch,
      incidentProvince_Month_Application_Results,
      incidentStation_Month_Application_Results,
      incidentSubDivision_Month_Application_Results,
      incidentDivision_Month_Application_Results,
      lineIncidentDay_Application_Results,
    };
  },
});
</script>

<template>
  <div class="ttp-layout px-4 pb-4">
    <div class="ttp-page-header-1">
      <h2 class="ttp-text-sm mt-4">ประเภทการแจ้งเหตุ</h2>
    </div>
    <div class="ttp-between mb-4">
      <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 w-full">
        <VueDatePicker :model-value="date" @update:model-value="handleDate" range :format="dateRangeFormat" />
        <component :is="defaultFormComponents.form.select.categoryCode.component" v-bind="defaultFormComponents.form.select.categoryCode.props" @updateValue="defaultFormComponents.form.select.categoryCode.onUpdateValue" />
      </div>
      <div>
        <component :is="defaultFormComponents.form.button.btnReset.component" v-bind="defaultFormComponents.form.button.btnReset.props" @click="defaultFormComponents.form.button.btnReset.click" />
      </div>
      <div>
        <component :is="defaultFormComponents.form.button.btnSearch.component" v-bind="defaultFormComponents.form.button.btnSearch.props" @click="defaultFormComponents.form.button.btnSearch.click" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามจังหวัด</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentProvince_Month_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามหน่วยงานสถานี</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentStation_Month_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามกองกำกับการ</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentSubDivision_Month_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
      <div class="ttp-layout p-4">
        <div class="ttp-page-header-1 py-0">
          <h2 class="ttp-text-sm">แยกตามกองบังคับการ</h2>
        </div>
        <canvas v-if="!loading()" ref="incidentDivision_Month_Application_Results"></canvas>
        <LoadingFormComponent v-else class="m-auto w-full" />
      </div>
    </div>

    <div class="ttp-layout p-4">
      <div class="ttp-page-header-1">
        <h2 class="ttp-text-sm mt-4">ประเภทการแจ้งเหตุ</h2>
      </div>
      <canvas v-if="!loading()" ref="lineIncidentDay_Application_Results"></canvas>
      <LoadingFormComponent v-else class="m-auto w-full" />
    </div>
  </div>
</template>

<style style="scss"></style>
