<script lang="ts">
import type { $enum, $string, AllowedTypes } from "@/configs/types/Shared/typeShare";
import { defineComponent, ref, watch, type PropType } from "vue";
import "vue-select/dist/vue-select.css";
import VSelect from "vue-select";

const FormSelectBoxDefaultComponent = defineComponent({
  name: "FormSelectBoxDefaultComponent",
  components: {
    VSelect,
  },
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      required: false,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    items: {
      type: Array as PropType<{ label: $string; value: $string }[] | $enum>,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Array, Object, "", null] as PropType<AllowedTypes>,
      required: false,
      default: "",
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const handleInput = (value: any) => {
      emit("updateValue", value);
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
    };
  },
  methods: {
    handleChange(option: any) {
      if (typeof this.functionProp === "function") {
        this.functionProp(option.value);
      }
    },
  },
});
export default FormSelectBoxDefaultComponent;
</script>

<template>
  <div v-if="isVisible" :class="classTagName ? classTagName : 'w-full'">
    <label v-if="isLabel" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ title }} <span v-if="isRequired && !isSpecial" class="text-ttp-error">*</span></label>
    <div class="relative">
      <VSelect :options="items" :reduce="(item: any) => item.value" :value="value" :placeholder="placeholder" label="label" class="border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg" @update:modelValue="handleInput" @option:selected="handleChange" @option:deselected="handleChange" />
    </div>
    <small v-if="isError && isLabel" class="relative text-ttp-text-error">{{ textError }}</small>
  </div>
</template>

<style>
.vs__dropdown-toggle {
  padding: 0;
  border: none;
}

.vs__search {
  border: none;
  padding: 0.65rem !important;
  line-height: 1.25 !important;
  background: transparent !important;
  font-size: 0.875rem !important;
  margin: 0;
}

.vs__selected {
  margin: 0;
}
</style>
