<script setup lang="ts">
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";

const { isOpen = false } = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits(["onClose"]);
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="emit('onClose')" class="relative z-90">
      <!-- Modal Backdrop -->
      <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900/75 backdrop-blur-sm z-40" />
      </TransitionChild>
      <!-- END Modal Backdrop -->

      <!-- Modal Dialog -->
      <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8 z-50 mt-20">
        <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0 scale-125" enter-to="opacity-100 scale-100" leave="ease-in duration-100" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-125">
          <DialogPanel class="mx-auto flex w-full max-w-md flex-col rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
            <div class="flex items-center justify-between px-5 py-4 dark:bg-gray-700/50 relative">
              <h3 class="font-medium">ตัวอย่างการเขียนรายงาน</h3>
              <div class="absolute -top-3 -right-3">
                <button @click="emit('onClose')" type="button" class="inline-flex items-center justify-center gap-2 rounded-full border border-gray-400 bg-white px-3 py-2 text-sm font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
                  <svg class="hi-solid hi-x -mx-1 inline-block size-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="grow px-5 pb-5 pt-2">
              <p class="text-sm">
                Ms. Nina สัญชาติอังกฤษ (หมายเลขโทรศัพท์ 09 8261 9630) พักอยู่ที่โรงแรมรามบุตรี วิลเลจ (ที่อยู่ 95 ซอย รามบุตรี แขวงชนะสงคราม เขตพระนคร กรุงเทพมหานคร 10200 ) แจ้งว่า เมื่อวันที่ 28/09/67 เวลา 17.40 น. ถูกพขร.สามล้อเครื่อง (ตุ๊ก ตุ๊ก) ให้ไปที่บริษัท พระอาทิตย์ทัวร์ แอนด์ ทราเวล (ที่อยู่ 59/1 ถ.วิสุทธิกษัตริย์ แขวงบางขุนเทียน เขตพระนคร กรุงเทพมหานคร 10200) นทท.ได้ซื้อโปรแกรมท่องเที่ยวเดินทางจากกระบี่ ไปยังเชียงราย ราคา 45,150 บาท ชำระผ่านบัตรเครดิตและเมื่อถึงวันเดินทางไม่มีรถมารับ-ส่งตามที่ร้านแจ้งไว้ นทท.คาดว่า ถูกหลอกและต้องการขอเงินคืน จึงต้องการขอความช่วยเหลือจาก ตร.ทท. เบื้องต้น จนท.1155 ได้ประสานไปยัง ร.ต.ท.ณัฐพงศ์ แสงรักษาวงศ์ ปฏิบัติหน้าที่ร้อยเวรประจำ ส.ทท.1 กก.1 บก.ทท.1 (สนามศุภชลาศัย) เป็นผู้รับเรื่องและดำเนินการต่อไป<br><br>
                ขั้นตอนการดำเนินการ: สายตรวจชุด 3 ได้เดินทางไปพบ นทท. จากการสอบถามทราบว่า นทท.โดยสารรถสามล้อจากบริเวณหน้าศาลหลักเมือง จากนั้นได้พาไปที่ร้านพระอาทิตย์ทัวร์ แอนด์ ทราเวล และตัดสินใจซื้อโปรแกรมทัวร์กับทางร้าน ต่อมาได้อ่านรีวิวทางอินเตอร์เน็ต แล้วเกิดความไม่มั่นใจว่าจะได้รับบริการจริง โดยทางบริษัทได้ชี้แจงรายละเอียด และนำหลักฐานการจองต่างๆ ให้สายตรวจ และ นทท.ทำการตรวจสอบ นทท.รู้สึกมั่นใจ และยินดีเดินทางตามโปรแกรมเดิม พร้อมกล่าวขอบคุณตำรวจท่องเที่ยวที่ให้ความช่วยเหลือ 
              </p>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
      <!-- END Modal Dialog -->
    </Dialog>
  </TransitionRoot>
</template>
